import React from "react";

const publicRoute = [
  {
    key: "home",
    component: React.lazy(() => import("../../views/home")),
    path:"/",
    authority: [],
    header: true,
    footer: true,
  },
  {
    key: "personal-trainer",
    path: `/personal-trainer`,
    component: React.lazy(() =>import("../../views/businessType/personalTrainer")),
    authority: [],
    header: true,
    footer: true,
  },
  {
    key: "gym-fitness-studio",
    path: `/gym-fitness-studio`,
    component: React.lazy(() =>import("../../views/businessType/gymFitnessStudio")),
    authority: [],
    header: true,
    footer: true,
  },
  {
    key: "features",
    path: `/features`,
    component: React.lazy(() => import("../../views/features")),
    authority: [],
    header: true,
    footer: true,
  },
  {
    key: "about",
    path: `/about`,
    component: React.lazy(() => import("../../views/about")),
    authority: [],
    header: true,
    footer: true,
  },
];

export default publicRoute;