import React from 'react'

const CheckMark = ({ size="80", color = "#0D98BA", onClick }) => {
    return (
        <>
            <svg width={size} height={size} onClick={onClick} viewBox="0 0 30 30"  fill={color} xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="15" fill="#0D98BA" />
                <path d="M12.6976 20C12.617 19.9973 12.5379 19.9779 12.465 19.9432C12.392 19.9084 12.3269 19.859 12.2736 19.7979L8.27359 15.7561C8.19732 15.7061 8.13329 15.6394 8.08632 15.5607C8.03935 15.4821 8.01067 15.3937 8.00246 15.3022C7.99424 15.2108 8.0067 15.1186 8.03889 15.0327C8.07107 14.9468 8.12215 14.8694 8.18827 14.8064C8.25438 14.7434 8.33381 14.6963 8.42054 14.6689C8.50727 14.6414 8.59904 14.6342 8.68893 14.6478C8.77883 14.6614 8.86447 14.6955 8.93943 14.7475C9.01438 14.7994 9.07667 14.8679 9.12159 14.9477L12.6976 18.5369L21.0736 10.0976C21.1864 10.0237 21.3204 9.99021 21.4543 10.0025C21.5881 10.0148 21.714 10.0721 21.8118 10.1653C21.9095 10.2585 21.9735 10.3821 21.9934 10.5164C22.0133 10.6507 21.988 10.7879 21.9216 10.9059L13.1216 19.7979C13.0683 19.859 13.0031 19.9084 12.9302 19.9432C12.8573 19.9779 12.7782 19.9973 12.6976 20Z" fill="white" />
            </svg>
        </>
    )
}

export default CheckMark
