import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from "react-router-dom";
import classNames from 'classnames';

import { Dropdown } from '../ui';
import Button from '../ui/button';

import { HeaderLogo } from '../../assets/svg'
import { RxHamburgerMenu } from "react-icons/rx";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const Header = ({ className }) => {
    const navigate = useNavigate();
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleHamburger = () => {
        setIsHamburgerOpen(!isHamburgerOpen);
    };

    const handleMenuItemClick = () => {
        setIsHamburgerOpen(false);
    };

    const dropdownTitle = (
        <div className={classNames(className, 'flex items-center gap-2')}>
            <div className="flex items-center gap-2 ">
                <div className="">Business type</div>
                <div className="">
                    {isDropdownOpen ? <FaChevronUp color='#0D98BA' /> : <FaChevronDown color='#0D98BA' />}
                </div>
            </div>
        </div>
    )

    return (
        <>
            <div className='bg-[#C2E4F0] w-full sticky top-0 z-20'>
                <div className='container flex justify-between items-center h-12 lg:h-[70px] '>
                    <div className='flex items-center gap-1 cursor-pointer' onClick={() => navigate("/")}>
                        <HeaderLogo className="w-6 h-6 lg:w-8 lg:h-8" size={32} />
                        <p className='uppercase text-lg font-semibold'>TrainerX</p>
                    </div>
                    <div className='hidden lg:block'>
                        <ul className='flex items-center gap-10'>
                            <li className='cursor-pointer'>
                                <Dropdown className="" renderTitle={dropdownTitle} placement="bottom-end" onClick={handleDropdown}>
                                    <Dropdown.Item className="gap-2" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                        <NavLink
                                            className={({ isActive }) => `menu-item-link font-medium ${isActive ? " overflow-hidden text-[#0D98BA]" : ""}`}
                                            to="/personal-trainer"
                                        >
                                            Personal trainer
                                        </NavLink>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="gap-2" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                        <NavLink
                                            className={({ isActive }) => `menu-item-link font-medium ${isActive ? " overflow-hidden text-[#0D98BA]" : ""}`}
                                            to="/gym-fitness-studio"
                                        >
                                            Gym and fitness studio
                                        </NavLink>
                                    </Dropdown.Item>
                                </Dropdown>
                            </li>
                            <li>
                                <NavLink
                                    key={"features"}
                                    to={`/features`}
                                    className={({ isActive }) => ` font-medium ${isActive ? " overflow-hidden text-[#0D98BA]" : ""}`}
                                >
                                    Features
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    key={"about"}
                                    to={`/about`}
                                    className={({ isActive }) => ` font-medium ${isActive ? " overflow-hidden text-[#0D98BA] " : ""}`}
                                >
                                    About
                                </NavLink>
                            </li>
                            <li className='flex gap-6'>
                                <NavLink to="https://portal.trainerx.co/client/sign-in">
                                    <Button className="text-[#0D98BA] border-[1px] border-[#0D98BA] rounded-full" >
                                        Sign in
                                    </Button>
                                </NavLink>

                                <NavLink to="https://portal.trainerx.co/client/sign-up">
                                    <Button className="bg-[#0D98BA] rounded-full" variant="solid"  >
                                        Get started
                                    </Button>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='lg:hidden'>
                        <RxHamburgerMenu size={24} onClick={handleHamburger} />
                    </div>
                </div>

                {isHamburgerOpen && (
                    <div className="fixed top-0 right-0 bg-white w-full h-full z-40 shadow-lg overflow-scroll  sm:w-1/2">
                        <nav className="flex flex-col items-start">

                            <div className=" border-b-[1px] border-[#000000] w-full h-12 flex items-center justify-between ">
                                <div className="w-2/3 flex items-center justify-between text-center">
                                    <FaArrowLeftLong className='ml-4' size={24} onClick={() => setIsHamburgerOpen(false)} />
                                    <span className="font-syne text-center font-bold text-2xl ">
                                        Menu
                                    </span>
                                </div>
                            </div>

                            <div
                                className="w-full flex justify-between items-center px-4 pb-2 pt-4 "
                                onClick={() => handleDropdown()}
                            >
                                <div>
                                    <p className="font-medium w-full flex ">Business type</p>
                                </div>
                                <div
                                    className="pr-4"
                                >
                                    {isDropdownOpen ? <FaChevronUp color="#0D98BA" size={16} /> : <FaChevronDown color="#0D98BA" size={16} />}
                                </div>
                            </div>

                            {isDropdownOpen ? (<>
                                <div className="w-11/12 mx-auto items-center ">
                                    <div className='px-4 py-2'>
                                        <NavLink
                                            className={({ isActive }) => `menu-item-link font-medium ${isActive ? " overflow-hidden text-[#0D98BA]" : ""}`}
                                            to="/personal-trainer"
                                            onClick={handleMenuItemClick}
                                        >
                                            Personal trainer
                                        </NavLink>
                                    </div>
                                    <div className='px-4 py-2'>
                                        <NavLink
                                            className={({ isActive }) => `menu-item-link font-medium ${isActive ? " overflow-hidden text-[#0D98BA]" : ""}`}
                                            to="/gym-fitness-studio"
                                            onClick={handleMenuItemClick}
                                        >
                                            Gym and fitness studio
                                        </NavLink>
                                    </div>
                                </div>
                            </>) : (<></>)}

                            <div className='px-4 py-2'>
                                <NavLink
                                    key={"features"}
                                    to={`/features`}
                                    className={({ isActive }) => ` font-medium  ${isActive ? " overflow-hidden text-[#0D98BA]" : ""}`}
                                    onClick={handleMenuItemClick}
                                >
                                    Features
                                </NavLink>
                            </div>

                            <div className='px-4 py-2'>
                                <NavLink
                                    key={"about"}
                                    to={`/about`}
                                    className={({ isActive }) => ` font-medium  ${isActive ? " overflow-hidden text-[#0D98BA] " : ""}`}
                                    onClick={handleMenuItemClick}
                                >
                                    About
                                </NavLink>
                            </div>

                            <div>
                                <div className={`flex justify-start items-center gap-2 pt-6 px-4`}>
                                    <NavLink to="https://portal.trainerx.co/client/sign-in" onClick={handleMenuItemClick}>
                                        <Button className="text-[#0D98BA] border-[1px] border-[#0D98BA] rounded-full" >
                                            Sign in
                                        </Button>
                                    </NavLink>

                                    <NavLink to="https://portal.trainerx.co/client/sign-up" onClick={handleMenuItemClick}>
                                        <Button className="bg-[#0D98BA] rounded-full" variant="solid"  >
                                            Get started
                                        </Button>
                                    </NavLink>
                                </div>
                            </div>

                        </nav>
                    </div>
                )}
            </div>
        </>
    )
}

export default Header;
