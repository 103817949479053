import React from "react";
import { HiExclamationCircle } from "react-icons/hi";

const DisplayError = (message) => {
  return (
    <>
      {message && (
        <div className="relative">
        <div className="flex items-center justify-left align-middle  text-red-500 font-semibold">
          <div>
            <HiExclamationCircle className="mr-2 text-red-500" size={20} />
          </div>
          <p className="w-full">{message}</p>
        </div>
        </div>
      )}
    </>
  );
};
export default DisplayError;
