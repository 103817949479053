import React from "react";

const FadedLine = ({ width, specificClasses,borderBottom }) => {
  return ( 
    <div
      className={`absolute left-[50%] translate-x-[-50%] h-[2px]  ${specificClasses}`}
      style={{
        background:`${borderBottom}`,
        width: `${width}`,
      }}
    ></div>
  );
};

export default FadedLine;