import React from 'react'

import { CloseButton } from '../../ui';

const Model = ({ ...props }) => {
    const {
        heading = "",
        headingClass = "",
        content = "",
        contentClass = "",
        setIsModelOpen } = props

    const handleClose = (e) => {
        e.stopPropagation();
        setIsModelOpen(false);
    };
    return (
        <>
            <div className="w-full h-full fixed top-0 left-0  bg-gray-800 bg-opacity-50 z-10 flex items-center justify-center"
          
            >
                <div className='container'>
                    <div className="2xl:w-1/3 lg:w-1/2 sm:w-3/4 shadow-md shadow-cyan-200/40  bg-white md:py-10 py-5 rounded-3xl w-full mx-auto relative"
                    //  style={{ boxShadow: '0 0 16px #0D98BA' }}
                    >
                        <div className='absolute top-3 right-3  cursor-pointer text-2xl text-[#0D98BA] p-2 rounded-full bg-[#F1F9FB]'>
                            <CloseButton className="" onClick={(e) => handleClose(e)} />
                        </div>
                        <div className=" flex flex-col justify-center ">
                            <p className={`${headingClass} font-semibold text-center text-lg md:text-2xl xl:text-3xl `}>
                                {heading}
                            </p>
                            <div className={`py-5 mx-5 md:px-8 ${contentClass}`}>
                                {content}
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </>
    )
}

export default Model
