import React from "react";

const LeftRightContentBanner = ({
    bannerClassName="",
    contentClass="flex-col-reverse md:flex-row",
    firstContentClass="md:w-1/2",
    secondContentClass="md:w-1/2",
    textContent,
    imgContent,
}) => {
    return (
        <>
            <div className={`${bannerClassName}`}>
                <div className="container">
                    <div className={`flex ${contentClass}  md:justify-center md:items-center  md:gap-10`}>
                        <div className={`${firstContentClass}`}>
                            {/* <div className="block md:hidden ">{imgContent}</div> */}
                            <div className={textContent ? `text-left pt-4 md:py-6 lg:py-9` : ""}>
                                <div>{textContent}</div>
                            </div>
                        </div>
                        <div className={` ${secondContentClass}`}>{imgContent}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeftRightContentBanner;