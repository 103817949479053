import React from "react";
import api from "../../../services/api";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup"

import { OpenNotificationPopup } from "../../../components/shared"


import { Button, InputField } from "../../../components/ui";
import DisplayError from "../../../components/shared/displayError/displayError";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Please enter your full name"),
    email: Yup.string()
        .required("Please enter your email")
        .email("Please enter valid email"),
    website: Yup.string()
        // .required('Please enter a website URL.')
        .url("Please enter a valid website URL"),
    message: Yup.string()
        .required("Message is required")
        .min(10, "Message must be at least 10 characters")
        .max(500, "Message must not exceed 500 characters"),
});

// console.log("yup", validationSchema)


const ContactUsForm = ({ setContactUsPopup }) => {

    const onSubmitConatctData = async (values, { setSubmitting, resetForm }) => {
        // console.log("onSubmitConatctData", values);
        try {
            let response = await api.post("client/send-contact", values)
            // console.log("🚀 ~ onSubmitConatctData ~ response:", response)
            if (response?.data?.success) {
                OpenNotificationPopup("success", response?.data?.message)
                resetForm()
                setContactUsPopup(false)
            }
        } catch (error) {
            console.error(error.message)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <>
            <div>

                <div className="flex justify-center">
                    <div className="w-full md:flex md:gap-10 lg:justify-center  ">
                        <div className="w-full">
                            <div className="">
                                <Formik
                                    initialValues={{
                                        name: "",
                                        email: "",
                                        website: "",
                                        message: "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        onSubmitConatctData(values, { setSubmitting, resetForm });
                                    }}
                                >
                                    {({ values, touched, errors, isSubmitting, setFieldValue }) => {
                                        // console.log("🚀 ~ ContactUsForm ~ errors:", errors)

                                        return (
                                            <Form className="flex flex-col gap-1">
                                                <div className="mb-4">
                                                    <Field
                                                        component={InputField}
                                                        className="w-full"
                                                        label="Your Name*"
                                                        placeholder="Enter your name"
                                                        name="name"
                                                        autoComplete="off"
                                                    />
                                                    {touched.name && errors.name ? (DisplayError(errors.name)) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Field
                                                        component={InputField}
                                                        type="email"
                                                        className=""
                                                        label="Email*"
                                                        name="email"
                                                        placeholder="Enter your email"
                                                        autoComplete="off"
                                                    />
                                                    {touched.email && errors.email ? (DisplayError(errors.email)) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Field
                                                        component={InputField}
                                                        type="url"
                                                        className=""
                                                        label="Website"
                                                        name="website"
                                                        placeholder="Enter your website link"
                                                        autoComplete="off"
                                                    />
                                                    {/* {touched.website && errors.website ? (DisplayError(errors.website)) : null} */}
                                                </div>
                                                <div className="mb-4">
                                                    <Field
                                                        component={InputField}
                                                        textArea
                                                        className=""
                                                        label="Message*"
                                                        name="message"
                                                        placeholder="Enter your message here"
                                                        autoComplete="off"
                                                    />
                                                    {touched.message && errors.message ? (DisplayError(errors.message)) : null}
                                                </div>
                                                <div className="flex justify-center">
                                                    <Button
                                                        variant="solid"
                                                        loading={isSubmitting}
                                                        className="bg-[#0D98BA] rounded-full"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUsForm;
