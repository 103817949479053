import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

// import Layout from "./components/layout";
import Views from "./views";
import { publicRoutes } from "../src/configs/routes.config";


import "./App.css";
import Header from "./components/template/header";
import Footer from "./components/template/footer";


function App() {
  const countApi = useRef(0);


  const location = useLocation();
  const [header, setHeader] = useState(false);
  const [footer, setFooter] = useState(false);

  useEffect(() => {
    if (!countApi.current) {
      countApi.current = countApi.current + 1;
    }
  }, [])

  useEffect(() => {
    const allRoutes = [...publicRoutes]
    const currentRoute = allRoutes?.find((route) => {
      const regex = new RegExp(`^${route.path.replace(/:slug/, '[^/]+')}$`);
      return regex.test(location.pathname);
    })
    if (currentRoute) {
      setHeader(currentRoute.header);
      setFooter(currentRoute.footer);
    } else {
      setHeader(false);
      setFooter(false);
    }
  }, [location.pathname]);

  return (
    <>
      {header && <Header/>}
      <Views/>
      {footer && <Footer/>}
    </>
  );
}

export default App;
