import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { HeaderLogo } from '../../assets/svg'
import Button from '../ui/button'
import { FaInstagram } from 'react-icons/fa'
import { FiFacebook } from "react-icons/fi";
import { BsTwitterX } from "react-icons/bs";
import { RiTiktokLine } from "react-icons/ri";
import { Model } from '../shared'
import ContactUs from '../../views/contactUs'

const Footer = () => {
    const navigate = useNavigate();
    const [contactUsPopup, setContactUsPopup] = useState(false)
    return (
        <>
            <div className='bg-[#0D98BA]'>
                <div className='container'>
                    <div className='text-white flex flex-col gap-8 py-10'>
                        <div className='flex justify-center'>
                            <div className='flex items-center gap-1 cursor-pointer' onClick={() => navigate("/")}>
                                <HeaderLogo size={40} />
                                <p className='uppercase text-lg font-semibold text-black'>TrainerX</p>
                            </div>
                        </div>
                        {/* <div>
                            <p className='  text-center'>When an unknown printer took galle tyand scram bled it to make typnknown pret specimen book printer took galle of tyand scram.</p>
                        </div> */}
                        <div>
                            <ul className='flex flex-col justify-center items-center gap-4 md:flex-row md:items-start md:gap-10'>
                                <li>
                                    <Link to="/personal-trainer">
                                        Personal trainer
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/gym-fitness-studio">
                                        Gym and fitness studio
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/">
                                        Pricing
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/features">
                                        Features
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about">
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={() => setContactUsPopup(true)}>
                                        Contact us
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className='flex  justify-center gap-5'>
                            <Link to={`https://www.instagram.com/trainerx2024/`} target="_blank">
                                <div className='p-2 border-[1px] rounded-full'>
                                    <FaInstagram size={24} />
                                </div>
                            </Link>
                            <Link to={`https://www.facebook.com/profile.php?id=61566502995994`} target="_blank">
                                <div className='p-2 border-[1px] rounded-full'>
                                    <FiFacebook size={24} />
                                </div>
                            </Link>
                            <Link to={`https://x.com/Trainer_x2024`} target="_blank">
                                <div className='p-2 border-[1px] rounded-full'>
                                    <BsTwitterX size={24} />
                                </div>
                            </Link>
                            {/* <Link to={`/`} target="_blank">
                                <div className='p-2 border-[1px] rounded-full'>
                                    <RiTiktokLine size={24} />
                                </div>
                            </Link> */}
                        </div>
                        <div className='flex justify-center items-center'>
                            <p className='w-[230px] text-center md:w-full'>Copyright ©2024 TrainerX. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>

            {contactUsPopup && (
                <Model
                    heading='Conatct us'
                    headingClass='text-[32px] text-semibold'
                    content={<><ContactUs setContactUsPopup={setContactUsPopup} /></>}
                    setIsModelOpen={setContactUsPopup}
                />
            )}
        </>
    )
}

export default Footer
