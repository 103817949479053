import React from 'react'
import ContactUsForm from './components/contactUsForm'

const ContactUs = ({setContactUsPopup}) => {
  return (
    <>
      <div>
        <ContactUsForm setContactUsPopup={setContactUsPopup}/>
      </div>
    </>
  )
}

export default ContactUs
